* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: #fafafa !important;
}

.clearfix::after {
  content: '';
  clear: both;
  display: table;
}

/* Typographic Scale */

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Roboto|Work+Sans:400,500,600');
@import url('https://fonts.googleapis.com/css?family=Bai+Jamjuree&display=swap');

@font-face {
  font-family: 'Noto Sans Thai UI';
  src: url('/font/NotoSansThaiUI-Regular.ttf');
}

h1 {
  font-family: 'Noto Sans Thai UI', 'Work Sans';
  font-size: 101.44px;
  letter-spacing: -1.5px;
  font-weight: 600;
}

h2 {
  font-family: 'Noto Sans Thai UI', 'Work Sans';
  font-size: 63.4px;
  letter-spacing: -0.5px;
  font-weight: 500;
}

h3 {
  font-family: 'Noto Sans Thai UI', 'Work Sans';
  font-size: 50.72px;
  letter-spacing: 0px;
  font-weight: 500;
}

h4 {
  font-family: 'Noto Sans Thai UI', 'Work Sans';
  font-size: 35.93px;
  letter-spacing: 0.25px;
  font-weight: 600;
  margin-bottom: 0 !important;
}

h5 {
  font-family: 'Noto Sans Thai UI', 'Work Sans';
  font-size: 25.36px;
  letter-spacing: 0px;
  font-weight: 400;
}

h6 {
  font-family: 'Noto Sans Thai UI', 'Work Sans';
  font-size: 21.13px;
  letter-spacing: 0.25px;
  font-weight: 500;
}

.headline4 {
  font-family: 'Noto Sans Thai UI', 'Work Sans';
  font-size: 35.93px;
  letter-spacing: 0.25px;
  font-weight: 600;
}

.headline5 {
  font-family: 'Noto Sans Thai UI', 'Work Sans';
  font-size: 25.36px;
  letter-spacing: 0px;
  font-weight: 400;
}

.headline6 {
  font-family: 'Noto Sans Thai UI', 'Work Sans';
  font-size: 21.13px;
  letter-spacing: 0.25px;
  font-weight: 500;
}

.body1 {
  font-family: 'Noto Sans Thai UI', 'Work Sans';
  font-size: 16.91px;
  letter-spacing: 0.5px;
  font-weight: 400;
}

.body2 {
  font-family: 'Bai Jamjuree', 'Roboto';
  font-size: 14px;
  letter-spacing: 0.25px;
  font-weight: 400;
}

.sub1 {
  font-family: 'Noto Sans Thai UI', 'Work Sans';
  font-size: 16.61px;
  letter-spacing: 0.15px;
  font-weight: 500;
}

.sub2 {
  font-family: 'Noto Sans Thai UI', 'Work Sans';
  font-size: 14.79px;
  letter-spacing: 0.1px;
  font-weight: 400;
}

.button {
  font-family: 'Noto Sans Thai UI', 'Work Sans';
  font-size: 14.79px;
  letter-spacing: 1.25px;
  font-weight: 400;
}

.caption {
  font-family: 'Bai Jamjuree', 'Roboto';
  font-size: 12px;
  letter-spacing: 0.4px;
  font-weight: 400;
}

/* workaround for disable letter spacing property in SVG */
.caption.chart-label {
  letter-spacing: normal;
}

.overline {
  font-family: 'Noto Sans Thai UI', 'Roboto';
  font-size: 12px;
  letter-spacing: 2px;
  font-weight: 400;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.38);
  border-radius: 2px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.marker-small {
  font-family: 'Noto Sans Thai UI', 'Work Sans';
  font-size: 14px;
  letter-spacing: 0.15px;
  font-weight: 500;
}

.marker-large {
  font-family: 'Noto Sans Thai UI', 'Work Sans';
  font-size: 21.13px;
  letter-spacing: 0.25px;
  font-weight: 500;
}

.leaflet-pane {
  z-index: 1;
}

#split .SplitPane {
  position: relative;
}

.Resizer {
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.confirm > .ant-modal-body {
  padding: 8px;
}

.no-arrow::-webkit-outer-spin-button,
.no-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.no-arrow {
  -moz-appearance: textfield;
}
