@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}
 
.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}
 
.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}
 
.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}
 
.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}
 
.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.ant-modal-content {

  border-radius: 6px;
}

.ant-modal-confirm .ant-modal-body {
  padding: 24px;
}

.ant-modal-body .ant-btn {
  min-width: 108px;
  height: 40px;
  border: 1px solid rgba(21, 55, 96, 0.12);
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 4px;
  font-size: 14.79px;
  letter-spacing: 1.25px;
  font-weight: 400;
}

.ant-modal-body .ant-btn:hover {
  background-color: rgba(21, 55, 96, 0.04);
  color: #153760;
  font-size: 14.79px;
}

.ant-modal-body .ant-btn-primary {
  background: #153760 ;
  border: none;
}

.ant-modal-body .ant-btn-primary:hover {
  background: #1B406D ;
  color: white;
  font-size: 14.79px;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
}